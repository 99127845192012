// components/AboutUs.js
import React from 'react';

const AboutUs = () => {
    return (
        <div className="max-w-screen-xl mx-auto my-8">
            <div className="flex flex-col px-5 md:px-0 md:flex-row justify-between space-y-8 md:space-y-0 md:space-x-8">
                <div className="w-full md:w-1/3">
                    <h2 className="text-lg md:text-2xl font-semibold border-b border-gray-300 pb-2">Who We Are</h2>
                    <p className="text-sm md:text-base mt-4">A SaaS short video and creator marketing platform that empowers media & entertainment companies, major consumer brands to create proprietary user-generated content and audiences.</p>
                </div>
                <div className="w-full md:w-1/3">
                    <h2 className="text-lg md:text-2xl font-semibold border-b border-gray-300 pb-2">Purpose</h2>
                    <p className="text-sm md:text-base mt-4">Enable meaningful direct connections between media, brands and content creators, in any direction.</p>
                </div>
                <div className="w-full md:w-1/3">
                    <h2 className="text-lg md:text-2xl font-semibold border-b border-gray-300 pb-2">Mission</h2>
                    <p className="text-sm md:text-base mt-4">Create connections that will unleash the potential to engage authentic audiences and monetization opportunities, through a customizable SaaS short video platform and an exchange hub.</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
