// components/Hero
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const textVariants = {
    initial: { opacity: 0, y: 20, transition: { duration: 0.5 } },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.5 } }
};

const Hero = () => {
    const baseText = "Unleash the future of ";
    const phrases = [
        "media audiences",
        "brand influence",
        "creator marketing",
        "meaningful connections"
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % phrases.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [phrases.length]);

    return (
        <div className="max-w-screen-xl mx-auto">
            <div className="text-center h-[300px] md:hidden mt-12 px-2">
                <p className="text-xl leading-tight text-[#2E2B26] px-2">
                    SaaS platform engaging proprietary audiences through short videos
                </p>
                <h1 className="text-3xl font-semibold text-[#2E2B26] mt-4">
                    {baseText}
                    <AnimatePresence mode="wait">
                        <motion.span 
                            key={index}
                            variants={textVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            className="block"
                        >
                            {phrases[index]}
                        </motion.span>
                    </AnimatePresence>
                </h1>
                <a href="https://calendly.com/findedutorres" target="_blank" rel="noopener noreferrer">
                    <button className="bg-black text-white font-semibold py-2 px-6 rounded-full mt-6 mb-6">
                        Schedule a call
                    </button>
                </a>
            </div>

            <div className="relative w-full mt-8 h-[188px] mt-[50px] md:mt-24 rounded-none md:rounded-3xl md:h-[355px] bg-gradient-to-r from-[#BDEF00] to-[#00D2ED]">
                <img 
                    src="/img/hero-image.png" 
                    alt="Hero"
                    className="absolute w-full max-w-hero max-h-hero left-1/2 top-1/2 -translate-x-1/2 transform -mt-56 md:mt-0 md:translate-y-[-50%] translate-y-mobile-up md:translate-y-mobile-up-0"
                />
            </div>

            <div className="hidden md:block text-center mt-36">
                <p className="font-inter text-lg text-[#2E2B26]">
                    SaaS platform engaging proprietary audiences through short videos
                </p>
                <h1 className="font-sans font-semibold text-6xl text-[#2E2B26] mt-4 mx-auto">
                    {baseText}
                    <AnimatePresence mode="wait">
                        <motion.span 
                            key={index}
                            variants={textVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            className="block"
                        >
                            {phrases[index]}
                        </motion.span>
                    </AnimatePresence>
                </h1>
                <a href="https://calendly.com/findedutorres" target="_blank" rel="noopener noreferrer">
                    <button className="bg-black text-white text-lg font-semibold py-2 px-8 rounded-full mt-12 hover:shadow-custom-shadow">
                        Schedule a call
                    </button>
                </a>
            </div>
        </div>
    );
}

export default Hero;

