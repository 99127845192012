// components/Newsletter.js
import React from 'react';

const Newsletter = () => {
    return (
        <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center py-10 mb-12 px-4 md:px-0">
            <div className="w-full md:w-1/2 px-4 md:px-0">
                <h2 className="text-2xl font-semibold text-[#2E2B26] mb-2">Stay Up to Date</h2>
                <p className="text-lg text-[#2E2B26]">Subscribe to our newsletter for updates</p>
            </div>
            <div className="md:px-0 md:1/2 mt-8 md:mt-0 ml-4 mr-4 md:mr-0 md:ml-20 md:items-end md:flex-row">
                <form action="https://arenaz.us21.list-manage.com/subscribe/post?u=1b6493d3ea8ccdf6a963407d6&amp;id=55089ef262&amp;f_id=00e1bae1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="w-full flex justify-between items-center" target="_blank">
                    <input type="email" name="EMAIL" placeholder="Email" className="w-3/4 md:w-[360px] h-[48px] p-2 text-lg border" style={{ borderColor: '#C3C2C1', borderWidth: '1px' }} required />
                    <button type="submit" className="bg-black text-white font-semibold px-12 py-2 rounded-3xl ml-4 hover:shadow-custom-shadow">Send</button>
                </form>
            </div>
        </div>
    );
};

export default Newsletter;
