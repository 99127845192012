// components/PlatformFeatures.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const PlatformFeatures = () => {
    const [bgStyle, setBgStyle] = useState({
        color: 'bg-[#F5F6FC]',
        image: 'url(/img/feature1.png)'  // Use url() for backgroundImage
    });

    const handleItemClick = (color, imagePath) => {
        setBgStyle({
            color: color,
            image: `url(${imagePath})`  // Ensure proper format for backgroundImage
        });
    };

    return (
        <>
            {/* Desktop Version */}
            <div className="max-w-screen-xl mx-auto flex justify-between items-start py-10 hidden md:flex">
                <div className="w-1/2 pr-12">
                    <div className="text-left mb-12">
                        <h2 className="text-xs font-normal leading-5 text-custom-gray">Our SaaS Platform</h2>
                        <h1 className="text-5xl font-semibold my-2">Main Features</h1>
                    </div>
                    <ul>
                        {renderListItem("Core Video Experience", "The core navigation is designed to provide a standard experience for users to consume, create, and interact with short-form video content.", 'bg-[#F5F6FC]', '/img/feature1.png', handleItemClick)}
                        {renderListItem("Split Screen Video Experience", "This navigation system allows media and users to seamlessly create or join video polls, challenges or multiple sorts of split screen interactions between two videos, while leveraging swipe gestures for intuitive interactions. All within a fluid user-friendly interface.", 'bg-[#00B7D4]', '/img/feature2.png', handleItemClick)}
                        {renderListItem("Video React Models", "It empowers users to engage creatively with content while also encouraging interaction and collaboration within your owned digital assets.", 'bg-[#AAE800]', '/img/feature3.png', handleItemClick)}
                    </ul>
                </div>
                <div className="w-[650px] h-[650px] flex justify-center rounded-3xl">
                    <div className="w-[650px] h-[650px] bg-cover rounded-3xl" style={{backgroundImage: bgStyle.image }}>
                    </div>
                </div>
            </div>

            {/* Mobile Version */}
            <div className="max-w-screen-sm mx-auto py-10 px-6 md:hidden">
                <div className="text-left -mb-4">
                    <h2 className="text-xs font-normal leading-5 text-custom-gray">Our SaaS Platform</h2>
                    <h1 className="text-3xl font-semibold my-2">Main Features</h1>
                </div>
                <ul>
                    {renderListItem("Core Video Experience", "The core navigation is designed to provide a standard experience for users to consume, create, and interact with short-form video content.", 'bg-[#F5F6FC]', '/img/feature1.png', handleItemClick)}
                    {renderListItem("Split Screen Video Experience", "This navigation system allows media and users to seamlessly create or join video polls, challenges or multiple sorts of split screen interactions between two videos, while leveraging swipe gestures for intuitive interactions. All within a fluid user-friendly interface.", 'bg-[#00B7D4]', '/img/feature2.png', handleItemClick)}
                    {renderListItem("Video React Models", "It empowers users to engage creatively with content while also encouraging interaction and collaboration within your owned digital assets.", 'bg-[#AAE800]', '/img/feature3.png', handleItemClick)}
                </ul>
            </div>
        </>
    );
};

function renderListItem(title, description, bgColor, bgImage, onClick) {
    return (
        <li className="mb-0 pb-3 md:border-b border-gray-300 py-10 cursor-pointer" onClick={() => onClick(bgColor, bgImage)}>
            <div className="flex justify-between items-center text-2E2B26">
                <h3 className="text-xl font-semibold font-sans mb-2">{title}</h3>
                <FontAwesomeIcon icon={faArrowRight} className="hidden md:block" />
            </div>
            <p className="text-base font-inter mb-6">
                {description}
            </p>
            {/* Mobile-only background image */}
            <div className="md:hidden w-full h-auto flex justify-center items-center rounded-3xl overflow-hidden">
                <div className="w-full bg-cover bg-center rounded-3xl" style={{ backgroundImage: `url(${bgImage})`, height: '100vw', minHeight: '220px' }}>
                </div>
            </div>
            <div className="md:hidden w-full border-b border-gray-300 mt-14 -mb-6" />
        </li>
    );
}

export default PlatformFeatures;

