// components/Advantages.js
import React from 'react';

const Advantages = () => {
    return (
        <>
            {/* Desktop Version */}
            <div className="max-w-screen-xl mx-auto my-8 hidden md:block">
                <div className="text-left mb-12">
                    <h2 className="text-xs font-normal leading-5 text-custom-gray">Value Proposition</h2>
                    <h1 className="text-5xl font-semibold my-2">Strategic Edge</h1>
                </div>
                <div className="flex flex-col px-5 md:px-0 md:flex-row justify-between space-y-8 md:space-y-0 md:space-x-8">
                    <div className="w-full md:w-1/4 flex flex-col">
                        <div className="min-h-[65px] max-h-[65px] flex items-end">
                            <h2 className="text-lg md:text-2xl font-semibold">Proprietary Audiences</h2>
                        </div>
                        <div className="border-b border-gray-300 pb-2 mt-2 mb-4"></div>
                        <div className="min-h-[140px] max-h-[140px] flex items-start">
                            <p className="text-sm md:text-base">Have complementary owned media assets to reduce risks associated with having your content mostly on 3rd party platforms.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 flex flex-col">
                        <div className="min-h-[65px] max-h-[65px] flex items-end">
                            <h2 className="text-lg md:text-2xl font-semibold">Controlled Experiences</h2>
                        </div>
                        <div className="border-b border-gray-300 pb-2 mt-2 mb-4"></div>
                        <div className="min-h-[140px] max-h-[140px] flex items-start">
                            <p className="text-sm md:text-base">Control your proprietary content & UGC, policies and monetization models.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 flex flex-col">
                        <div className="min-h-[65px] max-h-[65px] flex items-end">
                            <h2 className="text-lg md:text-2xl font-semibold">First Party Data</h2>
                        </div>
                        <div className="border-b border-gray-300 pb-2 mt-2 mb-4"></div>
                        <div className="min-h-[140px] max-h-[140px] flex items-start">
                            <p className="text-sm md:text-base">Full access to an invaluable pool of your proprietary users, creators and audience data.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 flex flex-col">
                        <div className="min-h-[65px] max-h-[65px] flex items-end">
                            <h2 className="text-lg md:text-2xl font-semibold">Direct Connections</h2>
                        </div>
                        <div className="border-b border-gray-300 pb-2 mt-2 mb-4"></div>
                        <div className="min-h-[140px] max-h-[140px] flex items-start">
                            <p className="text-sm md:text-base">Build direct relationships with upcoming content creators, leveraging your proprietary content and proper incentives - all within your online assets.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile Version */}
            <div className="w-full mx-auto my-0 pl-6 block md:hidden">
                <div className="text-left mb-6">
                    <h2 className="text-xs font-normal leading-5 text-custom-gray">Value Proposition</h2>
                    <h1 className="text-3xl font-semibold my-2">Strategic Edge</h1>
                </div>
                <div className="flex flex-row overflow-x-auto">
                    <div className="flex flex-col flex-shrink-0 mr-4 max-w-[230px]">
                        <div className="flex flex-col">
                            <h2 className="text-xl font-semibold">Proprietary Audiences</h2>
                            <div className="border-b border-gray-300 pb-2 mt-2 mb-4"></div>
                            <p className="text-base">Have complementary owned media assets to reduce risks associated with having your content mostly on 3rd party platforms.</p>
                        </div>
                    </div>
                    <div className="flex flex-col flex-shrink-0 mr-4 max-w-[230px]">
                        <div className="flex flex-col">
                            <h2 className="text-xl font-semibold">Controlled Experiences</h2>
                            <div className="border-b border-gray-300 pb-2 mt-2 mb-4"></div>
                            <p className="text-base">Control your proprietary content & UGC, policies and monetization models.</p>
                        </div>
                    </div>
                    <div className="flex flex-col flex-shrink-0 mr-4 max-w-[230px]">
                        <div className="flex flex-col">
                            <h2 className="text-xl font-semibold">First Party Data</h2>
                            <div className="border-b border-gray-300 pb-2 mt-2 mb-4"></div>
                            <p className="text-base">Full access to an invaluable pool of your proprietary users, creators and audience data.</p>
                        </div>
                    </div>
                    <div className="flex flex-col flex-shrink-0 max-w-[230px]">
                        <div className="flex flex-col">
                            <h2 className="text-xl font-semibold">Direct Connections</h2>
                            <div className="border-b border-gray-300 pb-2 mt-2 mb-4"></div>
                            <p className="text-base">Build direct relationships with upcoming content creators, leveraging your proprietary content and proper incentives - all within your online assets.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Advantages;

