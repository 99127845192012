// components/Slide.js
import React from 'react';

const Slide = ({ bgColor, slideNumber, title, description, imageUrl, altText }) => {
  // Include className in the outermost div's class list
  return (
    <>
    <div className={`hidden md:block max-w-[960px] min-w-[960px] rounded-3xl mx-auto ${bgColor}`}>
      <div className="flex h-[540px] overflow-hidden">
        {/* Text column */}
        <div className="w-1/2 flex flex-col justify-start items-start px-16">
          <p className="text-5xl font-semibold mt-12">{slideNumber}</p>
          <h2 className="w-[385px] mt-8 mb-4 text-5xl font-semibold">{title}</h2>
          <p className="w-[385px] text-base font-normal mt-4">{description}</p>
        </div>
        {/* Image column */}
        <div className="w-1/2 flex justify-end">
          <img src={imageUrl} alt={altText} className="h-full w-auto object-cover rounded-r-3xl" />
        </div>
      </div>
    </div>
    <div className={`md:hidden rounded-3xl ${bgColor}`} style={{ width: '290px', height: '850px'}}>
      {/* Text column */}
      <div className="p-6" style={{ width: '290px', height: '480px' }}>
        <p className="text-3xl font-semibold mt-6">{slideNumber}</p>
        <h2 className="mt-4 text-3xl font-semibold">{title}</h2>
        <p className="mt-4 text-base font-normal">{description}</p>
      </div>
      {/* Image column */}
      <div className="w-full rounded-3xl" style={{ display: 'flex', alignItems: 'flex-end', height: '370px' }}>
        <img src={imageUrl} alt={altText} className="w-full h-auto object-cover rounded-3xl" />
      </div>
    </div>
    </>
  );
};

export default Slide;
