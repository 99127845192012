// App.js
import React, { useRef } from 'react';
import Menu from './components/Menu';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import ConnectingPeople from './components/ConnectingPeople';
import Advantages from './components/Advantages';
import Footer from './components/Footer';
import FullWidthImage from './components/FullWidthImage';
import PlatformFeatures from './components/PlatformFeatures';
import Newsletter from './components/Newsletter';

function App() {
    const heroRef = useRef(null);
    const aboutUsRef = useRef(null);
    const connectingPeopleRef = useRef(null);
    const advantagesRef = useRef(null);
    const featuresRef = useRef(null);
    const demoRef = useRef(null);

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Function to scroll with an offset of 40px
    const scrollToRef = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop - 100,  // Subtract 40px to adjust for any fixed elements like a header
            behavior: 'smooth'
        });
    };

    return (
        <div>
            <Menu 
                onHero={scrollToTop}
                onAboutUs={() => scrollToRef(aboutUsRef)}
                onConnectingPeople={() => scrollToRef(connectingPeopleRef)}
                onAdvantages={() => scrollToRef(advantagesRef)}
                onFeatures={() => scrollToRef(featuresRef)}
                onDemo={() => scrollToRef(demoRef)}
            />
            <div ref={heroRef}><Hero /></div>
            <div className="hidden md:block mt-24 mb-24 w-full h-px bg-[#DADADA]"></div>
            <div ref={aboutUsRef}><AboutUs /></div>
            <div className="mt-12 mb-12 md:mt-24 md:mb-24 w-full h-px bg-[#DADADA]"></div>
            <div ref={connectingPeopleRef}><ConnectingPeople /></div>
            <div className="mt-12 mb-12 md:mt-24 md:mb-24 w-full h-px bg-[#DADADA]"></div>
            <div ref={advantagesRef}><Advantages /></div>
            <FullWidthImage src="/img/image.png" /> 
            <div className="-mt-6 mb-6 md:mt-12 md:mb-12 w-full h-px bg-[#DADADA]"></div>
            <div ref={featuresRef}><PlatformFeatures /></div>
            <div ref={demoRef}><Newsletter /></div>
            <Footer />
        </div>
    );
}

export default App;
