// components/ConnectingPeople.js
import React from 'react';
import Slide from './Slide';

const ConnectingPeople = () => {
    return (
        <>
            <div className="max-w-screen-xl mx-auto my-2 md:my-6">
                <div className="text-left px-4 md:px-0">
                    <h2 className="text-xs font-normal leading-5 text-custom-gray">Creator Economy Marketplace</h2>
                    <h1 className="text-3xl md:text-5xl font-semibold my-2">Creator Marketing Exchange</h1>
                    <p className="text-sm md:text-base font-normal">AI powered connection hub, on top of our video platform</p>
                </div>
            </div>
            <div className="w-full overflow-x-auto py-6 md:py-12 bg-white">
                <div className="flex w-5/6 space-x-6 md:space-x-10 px-0 md:px-0 md:mx-auto max-w-[1280px]">
                    <Slide
                        bgColor="bg-[#AAE800]"
                        slideNumber="01"
                        title="Consumer Brands"
                        description="Reach new consumers, boost awareness and sales by connecting organically with relevant content creators and media channels. Reduce reliance on paid media and third party platforms by creating additional proprietary media channels and audiences effortlessly, maintaining full control of your valuable user data."
                        imageUrl="/img/slide01.png"
                        altText="Happy content creator"
                    />
                    <Slide
                        bgColor="bg-[#F5F6FC]"
                        slideNumber="02"
                        title="Content Creators"
                        description="Bypass algorithm challenges and build new media channels associated with highly visible partners. For the majority of content creators looking for more exposure, connect with media & entertainment companies and brands for greater exposure and be able to monetize your content creatively and fairly."
                        imageUrl="/img/slide02.png"
                        altText="Happy content creator"
                    />
                    <Slide
                        bgColor="bg-[#00B7D4]"
                        slideNumber="03"
                        title="Media & Entertainment"
                        description="Connect with content creators and brands on your own platform, reducing reliance and avoiding risks of sharing your content mostly on third-party platforms. Engage with new audiences through new video engagement experiences, without the hassle of software development and maintenance."
                        imageUrl="/img/slide03.png"
                        altText="Happy content creator"
                    />
                </div>
            </div>
        </>
    );
}

export default ConnectingPeople;
