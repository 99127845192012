// components/Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="bg-black text-white w-full">
            <div className="max-w-screen-xl mx-auto flex justify-between items-center w-full px-4 h-[136px] hidden md:flex">
                {/* Desktop Layout: Unchanged */}
                <div>
                    <img src="/img/logo.svg" alt="Arenaz Logo" className="h-8" style={{ filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%)' }}/>
                </div>
                <div className="text-[#848484] text-sm">
                    © 2024 Arenaz. All rights reserved.
                </div>
                <div className="flex items-center">
                    <a href="mailto:contact@arenaz.co" className="text-white text-sm mr-2">
                        contact@arenaz.co
                    </a>
                    <FontAwesomeIcon icon={faInstagram} />
                </div>
            </div>
            {/* Mobile Layout: Only shows on screens smaller than md */}
            <div className="w-full px-6 py-6 md:hidden flex flex-col items-start justify-center">
                <div className="mb-3 w-full">
                    <img src="/img/logo.svg" alt="Arenaz Logo" className="h-8" style={{ filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%)' }}/>
                </div>
                <div className="flex justify-between items-center w-full mb-3">
                    <a href="mailto:contact@arenaz.co" className="text-white text-sm">
                        contact@arenaz.co
                    </a>
                    <FontAwesomeIcon icon={faInstagram} />
                </div>
                <div className="text-[#848484] text-sm w-full">
                    © 2024 Arenaz. All rights reserved.
                </div>
            </div>
        </footer>
    );
}

export default Footer;
