// components/FullWidthImage.js
import React from 'react';

const FullWidthImage = ({ src }) => {
    // Calculate the height based on max width of screen-xl and image aspect ratio
    const maxWidth = 1280; // This is the typical max width for Tailwind's screen-xl
    const originalWidth = 2234;
    const originalHeight = 1064;
    const aspectRatio = originalWidth / originalHeight;
    const calculatedHeight = maxWidth / aspectRatio;

    return (
        <>
            {/* Desktop Version */}
            <div className="max-w-screen-xl mx-auto mb-24 hidden md:block">
                <img
                    src={src}
                    alt="Arenaz Happy content creators"
                    style={{
                        maxWidth: '100%',
                        minWidth: '100%',
                        height: Math.min(580, calculatedHeight) + 'px', // Constrain height to 540px max
                        objectFit: 'contain'
                    }}
                />
            </div>

            {/* Mobile Version */}
            <div className="max-w-screen-sm mx-auto mt-12 px-6 mb-24 block md:hidden">
                <img
                    src={src}
                    alt="Arenaz Happy content creators"
                    style={{
                        maxWidth: '100%',
                        minWidth: '100%',
                        height: 'auto',
                        objectFit: 'contain'
                    }}
                />
            </div>
        </>
    );
};

export default FullWidthImage;
