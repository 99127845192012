// components/Menu.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Menu = ({ onHero, onAboutUs, onConnectingPeople, onAdvantages, onFeatures, onDemo }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Effect to toggle the body scroll based on the menu state
    useEffect(() => {
        const body = document.body;
        if (isOpen) {
            body.style.overflow = 'hidden';  // Lock the scroll when the menu is open
        } else {
            body.style.overflow = 'visible';  // Enable scroll when the menu is closed
        }

        // Cleanup function to reset overflow when component unmounts
        return () => {
            body.style.overflow = 'visible';
        };
    }, [isOpen]);  // Dependency array with isOpen to run effect only when isOpen changes

    // Function to handle link click
    const handleLinkClick = (action) => {
        action();  // Perform the scroll action
        setIsOpen(false);  // Close the menu
    };

    return (
        <>
            <div className="sticky top-0 z-50 h-21 md:h-20 bg-white py-4 w-full min-w-full">
                <header className="bg-white flex justify-between items-center max-w-screen-xl mx-auto">
                    <div className="ml-5 mt-3 md:ml-0">
                        <a onClick={() => handleLinkClick(onHero)} href="#future">
                            <img src="/img/logo.svg" alt="arenaz" className="h-8"/>
                        </a>
                    </div>
                    {/* Hamburger menu for mobile screens */}
                    <button onClick={toggleMenu} className="text-3xl m-5 mr-5 md:m-0 md:hidden">
                        {isOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
                    </button>
                    <div className="absolute mt-0 top-full left-0 w-full h-px bg-[#DADADA] z-40 md:hidden"></div>
                    {/* Navigation menu */}
                    <nav className={`${isOpen ? "flex" : "hidden"} md:flex flex-col md:flex-row items-center w-full md:w-auto absolute md:relative bg-white md:bg-transparent top-full left-0 md:top-0 h-screen md:h-auto z-30 transition-all duration-300 ease-in-out`}>
                        <ul className="flex flex-col mt-12 md:mt-0 md:flex-row items-end md:items-center space-y-10 md:space-y-0 md:space-x-4 p-8 md:p-0 w-full text-right md:text-left">
                            <li><a onClick={() => handleLinkClick(onHero)} href="#future" className="text-custom-gray text-2xl md:text-sm font-sans hover:border-b hover:border-custom-gray w-full">Unleash the future</a></li>
                            <li><a onClick={() => handleLinkClick(onConnectingPeople)} href="#creator-economy" className="text-custom-gray text-2xl md:text-sm font-sans hover:border-b hover:border-custom-gray w-full">Exchange Hub</a></li>
                            <li><a onClick={() => handleLinkClick(onAdvantages)} href="#advantages" className="text-custom-gray text-2xl md:text-sm font-sans hover:border-b hover:border-custom-gray w-full">Strategic Edge</a></li>
                            <li><a onClick={() => handleLinkClick(onFeatures)} href="#features" className="text-custom-gray text-2xl md:text-sm font-sans hover:border-b hover:border-custom-gray w-full">Main Features</a></li>
                            <li><a href="https://calendly.com/arenaz" rel="noopener noreferrer" target="_blank" className="hidden md:block bg-black text-white text-xl md:text-sm font-sans py-4 px-6 md:py-2 md:px-4 rounded-full w-full hover:shadow-custom-shadow">Schedule a call</a></li>
                        </ul>
                    </nav>
                </header>
                <div className="hidden md:block mt-5 mb-0 w-full h-px bg-[#DADADA]"></div>
            </div>
        </>
    );
}

export default Menu;
